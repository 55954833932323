import React, { useEffect, useState } from 'react';
import { RouteComponentProps, useLocation, withRouter } from 'react-router';
import { Link } from 'react-router-dom';
import {
  AppBar,
  Box,
  Button,
  Container,
  Drawer,
  Hidden,
  IconButton,
  LinearProgress,
  MenuItem,
  Select,
  SwipeableDrawer,
  Toolbar,
  Tooltip,
  Typography,
} from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import Sidebar from '../../components/Sidebar/Sidebar';
import PrivateRoutes from '../../routes/PrivateRoutes';
import { TRoutes, logout } from '../../utils/helpers';
import useStyles from './styles';
import PersonIcon from '@material-ui/icons/Person';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import { StoreContext } from '../../utils/store';
import { useSnackbar } from 'notistack';
import { useApolloClient, useQuery } from '@apollo/client';
import { getStatusesList } from '../../GraphQL/queries/getStatusesList';
import { QUERY_EXCHANGE_RATE } from '../../GraphQL/queries/getTheExchangeRate';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import LanguageIcon from '@material-ui/icons/Language';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import { COLORS, LOCAL_STORAGE_KEYS } from '../../utils/constants';
import { QUERY_DELIVERY_COUNTRIES } from '../../GraphQL/queries/getDeliveryCountries';
import {
  useGetConfigurationListQuery,
  useGetDeliveryCountriesLazyQuery,
  useGetWhiteLabelConfigQuery,
  User,
  UserRoles,
} from '../../generated/graphql';
import { useChangeLanguage } from '../../hooks/useChangeLanguage';
import NotificationsIconComponent from '../../components/NotificationIconComponent/NotificationIconComponent';

import SettingsSelect from '../../components/SettingsSelectComponents/SettingsSelect';
import { getLebalFileUrl } from '../../utils/getLebalFileUrl';

interface PrivateLayoutProps extends RouteComponentProps {
  user: User;
  history: any;
}

function PrivateLayout(props: PrivateLayoutProps) {
  const location = useLocation();
  const currentPath = location.pathname;
  const isDashboardPage = currentPath === TRoutes.MAIN;
  const [isSidebarHidden, setIsSidebarHidden] = useState(
    localStorage.getItem(LOCAL_STORAGE_KEYS.IS_SIDEBAR_HIDDEN) === 'true' ||
      false,
  );
  const { data: configData } = useGetWhiteLabelConfigQuery();

  const drawerBgColor =
    configData?.getWhiteLabelConfig?.backgroundColorHexCode || '';

  const mainLogo = getLebalFileUrl(
    configData?.getWhiteLabelConfig?.mainLogoPath || '',
  );

  const sidebarLogo = getLebalFileUrl(
    configData?.getWhiteLabelConfig?.sidebarLogoPath || '',
  );

  const classes = useStyles({ isSidebarHidden, drawerBgColor });
  const [isMobileOpen, setMobileOpen] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const { setDeliveryStatuses, setPaymentStatuses } =
    React.useContext(StoreContext);
  const client = useApolloClient();

  const { changeLang, currentLanguage, t } = useChangeLanguage();

  const [
    queryLazyGetCountries,
    {
      data: dataQueryLazyCountries,
      loading: isLoadingQueryLazyCountries,
      error: errorQueryLazyCountries,
    },
  ] = useGetDeliveryCountriesLazyQuery();

  useEffect(() => {
    const deliveryCountries = JSON.parse(
      localStorage.getItem(LOCAL_STORAGE_KEYS.DELIVERY_COUNTRIES) || '[]',
    );
    if (deliveryCountries?.length) {
      client.writeQuery({
        query: QUERY_DELIVERY_COUNTRIES,
        data: {
          deliveryCountries: deliveryCountries,
        },
      });
    } else {
      queryLazyGetCountries();
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (dataQueryLazyCountries?.deliveryCountries?.length) {
      localStorage.setItem(
        LOCAL_STORAGE_KEYS.DELIVERY_COUNTRIES,
        JSON.stringify(dataQueryLazyCountries.deliveryCountries),
      );
    }
  }, [dataQueryLazyCountries]);

  const {
    data,
    error: errorStatuses,
    loading: loadingStatuses,
  } = useQuery(getStatusesList, {
    fetchPolicy: 'cache-and-network',
  });

  const { error: errorExchangeRates, loading: isLoadingExchangeRates } =
    useQuery(QUERY_EXCHANGE_RATE, {
      fetchPolicy: 'cache-first',
    });

  const { loading: isLoadingConfigurationList, error: errorConfigurationList } =
    useGetConfigurationListQuery({
      fetchPolicy: 'cache-and-network',
      // skip: props.user.role !== 'SUPER_ADMIN',
      onCompleted: (data) => {
        if (data) {
          localStorage.setItem(
            LOCAL_STORAGE_KEYS.CURRENT_APP_CURRENCY,
            data.getConfigurationList['current_app_currency'],
          );
          localStorage.setItem(
            LOCAL_STORAGE_KEYS.RATE_USD,
            data.getConfigurationList['rateUSD'],
          );
        }
      },
    });

  // We need guaranties that user is already authorized. We can save it to localStorage,
  // check if statuses exists before query, and clean localStorage after logout()
  useEffect(() => {
    if (data?.getStatusesList?.DeliveryStatusList) {
      setDeliveryStatuses(data.getStatusesList.DeliveryStatusList);
    }
    if (data?.getStatusesList?.PaymentStatusList) {
      setPaymentStatuses(data.getStatusesList.PaymentStatusList);
    }
    // eslint-disable-next-line
  }, [data]);

  // const {data:currencyData,loading} =

  if (
    loadingStatuses ||
    isLoadingExchangeRates ||
    isLoadingQueryLazyCountries ||
    isLoadingConfigurationList
  ) {
    return <LinearProgress style={{ height: 10 }} />;
  }

  if (errorQueryLazyCountries)
    enqueueSnackbar(errorQueryLazyCountries.message, { variant: 'error' });
  if (errorStatuses)
    enqueueSnackbar(errorStatuses.message, { variant: 'error' });
  if (errorExchangeRates)
    enqueueSnackbar(errorExchangeRates.message, { variant: 'error' });
  if (errorConfigurationList)
    enqueueSnackbar(errorConfigurationList.message, { variant: 'error' });

  const handleToggleSidebar = () => {
    setIsSidebarHidden((prevState) => {
      localStorage.setItem(
        LOCAL_STORAGE_KEYS.IS_SIDEBAR_HIDDEN,
        prevState ? 'false' : 'true',
      );
      return !prevState;
    });
  };

  const handleLogout = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    logout(props.history);
  };

  const handleRedirectToNotificationPage = () => {
    props.history.push(TRoutes.NOTIFICATIONS);
  };

  const getHeaderTitleText = () => {
    switch (currentPath) {
      case TRoutes.MAIN:
        return t('app.dashboard');

      default:
        return '';
    }
  };

  return (
    <div
      className={classes.root}
      // TODO temporary style for dashboad page. It will be doing for other pages
      style={{
        backgroundColor: isDashboardPage
          ? COLORS.DASHBOARD_LIGHT_GREY
          : COLORS.WHITE,
      }}
    >
      <AppBar
        position='absolute'
        className={classes.appBar}
        style={{
          backgroundColor: isDashboardPage ? COLORS.WHITE : 'transparent',
        }}
      >
        <Toolbar className={classes.toolbar}>
          <Hidden lgUp implementation='css'>
            <IconButton
              aria-label='open drawer'
              edge='start'
              className={classes.toggleMenu}
              onClick={() => setMobileOpen(!isMobileOpen)}
            >
              <MenuIcon />
            </IconButton>
          </Hidden>
          <Box className={classes.headerWrapper}>
            <Typography
              variant='h2'
              align='center'
              className={classes.headerTitle}
            >
              {getHeaderTitleText()}
            </Typography>
            <Box className={classes.linkWrapper}>
              <Select
                className={classes.languageSelect}
                value={0}
                onChange={changeLang}
              >
                <MenuItem value={0} style={{ display: 'none' }}>
                  <IconButton className={classes.headerIconWrap}>
                    <LanguageIcon style={{ color: COLORS.BLACK }} />
                  </IconButton>
                  <p className={classes.language}>
                    {currentLanguage.charAt(0).toUpperCase() +
                      currentLanguage.slice(1)}
                  </p>
                </MenuItem>
                <MenuItem value={'ru'}>
                  <div className={classes.language}>
                    {t('app.languageRussian')}
                  </div>
                </MenuItem>
                <MenuItem value={'en'}>
                  <div className={classes.language}>
                    {t('app.languageEnglish')}
                  </div>
                </MenuItem>
              </Select>
              {props.user.role === UserRoles.SuperAdmin ? (
                <NotificationsIconComponent
                  onClick={handleRedirectToNotificationPage}
                />
              ) : null}
              <SettingsSelect userRole={props.user.role} />
              <Link to={'/#'} className={classes.person}>
                <PersonIcon className={classes.personIcon} />
                <Hidden smDown>
                  {props.user.name} {props.user.warehouse?.code}
                </Hidden>
              </Link>
              <div>
                <Tooltip title={t('app.signOutAndClearLocalStorage')}>
                  <Button onClick={handleLogout} className={classes.signOut}>
                    <ExitToAppIcon />
                  </Button>
                </Tooltip>
              </div>
            </Box>
          </Box>
        </Toolbar>
      </AppBar>
      <nav className={classes.drawer}>
        <Hidden mdUp implementation='css'>
          <SwipeableDrawer
            variant='temporary'
            open={isMobileOpen}
            onOpen={() => setMobileOpen(true)}
            onClose={() => setMobileOpen(false)}
            classes={{
              paper: classes.drawerPaper,
            }}
            ModalProps={{
              keepMounted: true,
            }}
          >
            <img className={classes.logo} alt='Pick & Pack' src={mainLogo} />

            <Sidebar
              onSelectItem={() => setMobileOpen(false)}
              role={props.user.role as string}
            />
          </SwipeableDrawer>
        </Hidden>
        <Hidden mdDown implementation='css'>
          <Drawer
            classes={{
              paper: classes.drawerPaper,
            }}
            variant='permanent'
            open
          >
            <Link to={'/'} className={classes.logoLink}>
              {isSidebarHidden ? (
                <img
                  src={sidebarLogo}
                  alt='PicknPack'
                  className={classes.logoWithHiddenSidebar}
                />
              ) : (
                <img
                  className={classes.logo}
                  alt='Pick & Pack'
                  src={mainLogo}
                />
              )}
            </Link>
            <Sidebar
              isSidebarHidden={isSidebarHidden}
              role={props.user.role as string}
            />
            <Box
              className={classes.sidebarToggler}
              onClick={() => handleToggleSidebar()}
            >
              <Tooltip
                title={
                  isSidebarHidden
                    ? t('app.expandSidebar')
                    : t('app.collapseSidebar')
                }
              >
                {isSidebarHidden ? (
                  <ArrowForwardIosIcon />
                ) : (
                  <ArrowBackIosIcon />
                )}
              </Tooltip>
            </Box>
          </Drawer>
        </Hidden>
      </nav>
      <main className={classes.content}>
        <div className={classes.toolbar} />
        <Container className={classes.container}>
          <PrivateRoutes role={props.user.role} />
        </Container>
      </main>
    </div>
  );
}

export default withRouter(PrivateLayout);
