import React, { useState } from 'react';
import clsx from 'clsx';
import { Collapse, List, ListItem, Tooltip } from '@material-ui/core';
import { NavLink } from 'react-router-dom';

import useStyles from './styles';
import { TRoutes, isListOpenLocalStorage } from '../../utils/helpers';
import GroupIcon from '@material-ui/icons/Group';
import SearchIcon from '@material-ui/icons/Search';
import CardMembershipIcon from '@material-ui/icons/CardMembership';
import ExposureIcon from '@material-ui/icons/Exposure';
import AccountBalanceWalletIcon from '@material-ui/icons/AccountBalanceWallet';
import KitchenIcon from '@material-ui/icons/Kitchen';
import LibraryBooksIcon from '@material-ui/icons/LibraryBooks';
import KeyboardReturnIcon from '@material-ui/icons/KeyboardReturn';
import { LOCAL_STORAGE_KEYS, sideBarListItems } from '../../utils/constants';
import LocalShippingIcon from '@material-ui/icons/LocalShipping';
import { UserRoles } from '../../generated/graphql';
import { useTranslation } from 'react-i18next';
import { sendingToWarehouseOptions } from './options';
import { SidebarCollapseListItem } from '../../utils/helperComponents';
import InsertChartOutlinedTwoToneIcon from '@material-ui/icons/InsertChartOutlinedTwoTone';

interface SidebarProps {
  isSidebarHidden?: boolean;
  onSelectItem?: () => void;
  role?: string | UserRoles;
}

const Sidebar: React.FC<SidebarProps> = ({
  isSidebarHidden = false,
  onSelectItem = () => {},
  role,
}) => {
  const classes = useStyles(isSidebarHidden);

  const { t } = useTranslation();

  const [listsOpen, setLists] = useState({
    sending: isListOpenLocalStorage(LOCAL_STORAGE_KEYS.IS_LIST_OPEN_SENDING),
    payments: isListOpenLocalStorage(LOCAL_STORAGE_KEYS.IS_LIST_OPEN_PAYMENTS),
    settings: isListOpenLocalStorage(LOCAL_STORAGE_KEYS.IS_LIST_OPEN_SETTINGS),
    sendingToWarehouse: isListOpenLocalStorage(
      LOCAL_STORAGE_KEYS.IS_LIST_OPEN_SENDING_TO_WAREHOUSE,
    ),
    reports: isListOpenLocalStorage(LOCAL_STORAGE_KEYS.IS_LIST_REPORTS),
    warehouseProducts: isListOpenLocalStorage(
      LOCAL_STORAGE_KEYS.IS_WAREHOUSE_PRODUCTS_LIST_OPEN,
    ),
    returns: isListOpenLocalStorage(LOCAL_STORAGE_KEYS.IS_LIST_OPEN_RETURNS),
  });

  const handleClickItem = (name: keyof typeof listsOpen) => () => {
    localStorage.setItem(
      'isListOpen' + name[0].toUpperCase() + name.substring(1),
      (!listsOpen[name]).toString(),
    );
    setLists({
      ...listsOpen,
      [name]: !listsOpen[name],
    });
  };

  return (
    <List className={classes.list} component='div'>
      <ListItem className={classes.listItem} button>
        <NavLink
          className={classes.listItemLink}
          activeClassName={classes.listItemLinkActive}
          exact
          onClick={() => {
            if (onSelectItem) return onSelectItem();
          }}
          to={TRoutes.MAIN}
        >
          <Tooltip title={t('app.dashboard')}>
            <InsertChartOutlinedTwoToneIcon
              className={classes.listItemLinkIcon}
            />
          </Tooltip>
          {!isSidebarHidden && t('app.dashboard')}
        </NavLink>
      </ListItem>
      <ListItem button className={classes.listItem}>
        <NavLink
          className={classes.listItemLink}
          exact
          activeClassName={classes.listItemLinkActive}
          onClick={() => onSelectItem()}
          to={TRoutes.CLIENTS}
        >
          <Tooltip title={t('app.clients')}>
            <GroupIcon className={classes.listItemLinkIcon} />
          </Tooltip>
          {!isSidebarHidden && t('app.clients')}
        </NavLink>
      </ListItem>
      <ListItem
        button
        className={clsx(classes.listItem, classes.listItemSubList)}
        onClick={handleClickItem(sideBarListItems.SENDING)}
      >
        <>
          <Tooltip title='Заказы'>
            <CardMembershipIcon className={classes.listItemLinkIcon} />
          </Tooltip>
          {!isSidebarHidden && t('app.orders')}
        </>
      </ListItem>
      <Collapse in={listsOpen.sending}>
        <List className={clsx(classes.list, classes.listSub)} component='div'>
          <ListItem
            className={clsx(classes.listItem, classes.listItemSub)}
            button
          >
            <NavLink
              to={TRoutes.PARCELS}
              className={classes.listItemLink}
              activeClassName={classes.listItemLinkActive}
              exact
              onClick={() => onSelectItem()}
            >
              {t('app.d2cShipments')}
            </NavLink>
          </ListItem>
          <ListItem
            className={clsx(classes.listItem, classes.listItemSub)}
            button
          >
            <NavLink
              className={classes.listItemLink}
              activeClassName={classes.listItemLinkActive}
              exact
              onClick={() => {
                if (onSelectItem) return onSelectItem();
              }}
              to={TRoutes.B2B_ORDERS}
            >
              {t('app.b2bShipments')}
            </NavLink>
          </ListItem>
        </List>
      </Collapse>

      <ListItem
        className={classes.listItem}
        button
        onClick={handleClickItem(sideBarListItems.RETURNS)}
      >
        <NavLink
          className={classes.listItemLink}
          activeClassName={classes.listItemLinkActive}
          style={{ whiteSpace: 'nowrap' }}
          exact
          onClick={() => {
            onSelectItem?.();
          }}
          to={TRoutes.RETURNS}
        >
          <Tooltip title={t('app.marketplaceOrders')}>
            <KeyboardReturnIcon className={classes.listItemLinkIcon} />
          </Tooltip>
          {!isSidebarHidden && t('app.returns')}
        </NavLink>
      </ListItem>

      <ListItem className={classes.listItem} button>
        <NavLink
          exact
          className={classes.listItemLink}
          activeClassName={classes.listItemLinkActive}
          onClick={() => onSelectItem()}
          to={TRoutes.TRACK}
        >
          <Tooltip title={t('app.trackByTrackingNumber')}>
            <SearchIcon className={classes.listItemLinkIcon} />
          </Tooltip>
          {!isSidebarHidden && t('app.track')}
        </NavLink>
      </ListItem>
      {/* {role === 'SUPER_ADMIN' ? (
        <ListItem className={classes.listItem} button>
          <a
            className={classes.listItemLink}
            target='_blank'
            rel='noopener noreferrer'
            onClick={() => onSelectItem()}
            href={`${process.env.REACT_APP_API_DOMAIN}/calculator`}
          >
            <ExposureIcon className={classes.listItemLinkIcon} />
            {!isSidebarHidden && t('app.calculator')}
          </a>
        </ListItem>
      ) : null} */}
      {role === 'SUPER_ADMIN' ? (
        <>
          <ListItem
            className={clsx(classes.listItem, classes.listItemSubList)}
            button
            onClick={handleClickItem(sideBarListItems.PAYMENTS)}
          >
            <AccountBalanceWalletIcon className={classes.listItemLinkIcon} />
            {!isSidebarHidden && t('app.payments')}
          </ListItem>

          <Collapse in={listsOpen.payments}>
            <List
              className={clsx(classes.list, classes.listSub)}
              component='div'
            >
              <ListItem
                className={clsx(classes.listItem, classes.listItemSub)}
                button
              >
                <NavLink
                  className={classes.listItemLink}
                  activeClassName={classes.listItemLinkActive}
                  exact
                  onClick={() => onSelectItem()}
                  to={TRoutes.PAYMENT_STATISTICS}
                >
                  {t('app.statistics')}
                </NavLink>
              </ListItem>
            </List>
          </Collapse>
        </>
      ) : null}
      <ListItem
        className={clsx(classes.listItem, classes.listItemSubList)}
        button
        onClick={handleClickItem(sideBarListItems.SENDING_TO_WAREHOUSE)}
      >
        <Tooltip title={t('app.incomingWarehouse')}>
          <LocalShippingIcon className={classes.listItemLinkIcon} />
        </Tooltip>
        {!isSidebarHidden && `${t('app.shipmentsToWarehouse')}`}
      </ListItem>
      <Collapse in={listsOpen.sendingToWarehouse}>
        <List className={clsx(classes.list, classes.listSub)} component='div'>
          {sendingToWarehouseOptions.map((option) => (
            <SidebarCollapseListItem
              key={option.title}
              listITemClassName={clsx(classes.listItem, classes.listItemSub)}
              navLinkClassName={classes.listItemLink}
              activeClassName={classes.listItemLinkActive}
              onSelectItem={onSelectItem}
              link={option.link}
              title={option.title}
            />
          ))}
        </List>
      </Collapse>
      {role === 'SUPER_ADMIN' ? (
        <ListItem
          classes={{
            root: classes.listItem,
            disabled: classes.listItemDisabled,
          }}
          button
        >
          <NavLink
            className={classes.listItemLink}
            activeClassName={classes.listItemLinkActive}
            exact
            onClick={() => {
              if (onSelectItem) return onSelectItem();
            }}
            to={TRoutes.WAREHOUSE}
          >
            <KitchenIcon className={classes.listItemLinkIcon} />
            {!isSidebarHidden && t('app.productsWarehouse')}
          </NavLink>
        </ListItem>
      ) : (
        <>
          <ListItem
            className={classes.listItem}
            button
            onClick={handleClickItem(sideBarListItems.WAREHOUSE_PRODUCTS)}
          >
            <NavLink
              className={classes.listItemLink}
              activeClassName={classes.listItemLinkActive}
              style={{ whiteSpace: 'normal' }}
              exact
              onClick={() => {
                if (onSelectItem) return onSelectItem();
              }}
              to={TRoutes.WAREHOUSE}
            >
              <Tooltip title={t('app.productsWarehouse')}>
                <KitchenIcon className={classes.listItemLinkIcon} />
              </Tooltip>
              {!isSidebarHidden && t('app.productsWarehouse')}
            </NavLink>
          </ListItem>
          <Collapse in={listsOpen.warehouseProducts}>
            <List
              className={clsx(classes.list, classes.listSub)}
              component='div'
            >
              <ListItem
                className={clsx(classes.listItem, classes.listItemSub)}
                button
              >
                <NavLink
                  className={classes.listItemLink}
                  activeClassName={classes.listItemLinkActive}
                  style={{ whiteSpace: 'normal' }}
                  exact
                  onClick={() => {
                    if (onSelectItem) return onSelectItem();
                  }}
                  to={TRoutes.WAREHOUSE}
                >
                  {t('app.products')}
                </NavLink>
              </ListItem>
              <ListItem
                className={clsx(classes.listItem, classes.listItemSub)}
                button
              >
                <NavLink
                  className={classes.listItemLink}
                  activeClassName={classes.listItemLinkActive}
                  style={{ whiteSpace: 'normal' }}
                  exact
                  onClick={() => {
                    if (onSelectItem) return onSelectItem();
                  }}
                  to={TRoutes.WAREHOUSE_RESIDUES}
                >
                  {t('app.leftovers')}
                </NavLink>
              </ListItem>
              <ListItem
                className={clsx(classes.listItem, classes.listItemSub)}
                button
              >
                <NavLink
                  className={classes.listItemLink}
                  activeClassName={classes.listItemLinkActive}
                  style={{ whiteSpace: 'normal' }}
                  exact
                  onClick={() => {
                    if (onSelectItem) return onSelectItem();
                  }}
                  to={TRoutes.WAREHOUSE_SWAP_HISTORY}
                >
                  {t('app.moving')}
                </NavLink>
              </ListItem>
            </List>
          </Collapse>
        </>
      )}
      {role === 'SUPER_ADMIN' ? (
        <ListItem
          classes={{
            root: classes.listItem,
            disabled: classes.listItemDisabled,
          }}
          button
        >
          <NavLink
            className={classes.listItemLink}
            activeClassName={classes.listItemLinkActive}
            exact
            onClick={() => {
              if (onSelectItem) return onSelectItem();
            }}
            to={TRoutes.REPORTS}
          >
            <Tooltip title={t('app.reports')}>
              <LibraryBooksIcon className={classes.listItemLinkIcon} />
            </Tooltip>
            {!isSidebarHidden && t('app.reports')}
          </NavLink>
        </ListItem>
      ) : null}
    </List>
  );
};

export default Sidebar;
